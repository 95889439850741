.ContentEditable__root {
  border: 0;
  font-size: 15px;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0;
  //max-height: calc(100vh - 175px);
  overflow: visible;
  //width: fit-content;
}
