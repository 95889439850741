.print-dashboard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //display: none;
  width: 100%;
  z-index: -2;
  background: #fff;
  //padding: 20px 32px;
  box-sizing: border-box;

  @media print {
    //position: static;
    z-index: 100;
  }



  .metric-to-dashboard-description {
    padding-left: 20px;
  }

  .recharts-layer {
    text {
      font-size: 7px!important;
    }
  }

  @media print {
    //position: static;
    z-index: 100;
  }

  .recharts-legend-wrapper {
    left: 20px!important;

    .uiKit-shape.__circle {
      width: 5px!important;
      height: 5px!important;
    }

    .uiKit-text {
      font-size: 6px!important;
    }

    .d-stack-column.spacing-0 > :not(:last-child) {
      margin-bottom: 4px!important;
    }
  }


  .dashboard-chart__wrapper {
    //margin-left: -24px;
  }

  .recharts-wrapper {
    padding-bottom: 0;
  }

  .recharts-cartesian-axis-tick {
    text {
      font-size: 7px!important;
      color: #8E939D;
    }

  }

  .user-info__container {
    margin-top: 4px;


    .uiKit-text {
      font-size: 7px!important;
    }

  }

  .ant-avatar {
    width: 11px!important;
    height: 11px!important;
    line-height: 11px!important;
    font-size: 7px;
  }

  .metric-to-dashboard__container__title {
    font-size: 7px!important;
  }

}

.print-dashboard-wrapper {
  width: 100%;
  max-width: 800px;
}
