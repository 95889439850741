@import "src/styles/abstracts/_mixins.scss";

:root[data-app-theme-id="1"] {
}
.main-app-sider__wrapper {
  background: var(--color-srf-primary) !important;


  .__state_indicator_line {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    transition: background-color 0.2s ease-in;
    background: var(--color-srf-primary);

    //&_communication, &_regulation {
    //  background: var(--color-brdr-light);
    //}

  }

  &:hover {
    .__state_btn {
      opacity: 1;
    }
  }

  .__state_btn {
    transition: all 0.15s;
    opacity: 0;
    position: absolute;
    right: -12px;
    top: 12px;
    box-shadow: none;
    //box-shadow: var(--shadow-down-sm) !important;
    &:hover + .__state_indicator_line {
      opacity: 1;
      background: var(--color-brdr-active);
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .__app_version {
    font-size: 8px;
    text-align: center;
    white-space: pre;
  }

  ul {
    overflow: auto;
    background: transparent !important;
    border-inline-end: unset !important;
    color: var(--color-txt-primary);
    //border-right: 2px solid var(--color-primary-base);
    svg {
      stroke: var(--color-icn-accent);
    }

    a {
      all: unset;
    }

    .ant-menu-item {
      margin: 4px !important;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      height: 36px !important;
      line-height: 36px !important;
      padding-left: 16px !important;

      &.ant-menu-item-only-child {
        padding-left: 40px !important;
      }

      &:hover {
        span {
          color: var(--color-txt-active) !important;
        }
      }

      .anticon {
        line-height: 36px !important;
      }
    }

    .ant-menu-item-selected,
    .ant-menu-item-active {
      background: var(--color-srf-active) !important;
      span {
        color: var(--color-txt-active) !important;
      }
    }

    .ant-menu-title-content,
    .-title-content {
      line-height: 36px !important;

      .anticon {
        line-height: 36px !important;
      }
    }

    .ant-menu-submenu-title {
      margin-block: 0 !important;
    }
  }

  .tg-item-name {
    vertical-align: middle;
    width: 100%;
    white-space: pre-wrap;
    display: inline-block;
    line-height: 1.1em;
  }
}

/* Контейнер для снежинок */
.snowflakes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Чтобы анимация не мешала кликам */
  overflow: hidden;
}


/* Анимация падения снежинок */
@keyframes fall {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

/* Снежинка */
.flake {
  position: absolute;
  top: -10px;
  font-size: 12px; /* Размер снежинки */
  color: #F0F3FF;
  animation: fall linear infinite;
}
