.format-select {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
    background: var(--color-layout-fill-secondary);
    border-radius: var(--border-radius-md);
  }
}
