.regulation-history {
  &__wrapper {
    overflow: hidden;
    //padding: 16px;
    //background-color: var(--color-white);
    //border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__title {
    //margin-top: 8px !important;
    //font-size: 16px !important;
    //font-weight: 500 !important;
  }


}

.regulation-history-header {
  height: 52px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid var(--color-calendar-default);
}

.regulation-history-comment {
  display: grid;
  gap: 16px;
}

.regulation-history-comment-row {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 12px;
  padding: 0 4px 0 16px;

  &__user {
    padding-top: 12px;
  }
}

.regulation-history-comment-footer {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}
