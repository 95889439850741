//.orgchart-page__options-button {
//  background: var(--color-white) !important;
//  //box-shadow: var(--shadow-down-sm);
//  //width: 28px;
//  //height: 28px;
//  border-radius: 4px !important;
//
//  svg {
//    fill: var(--color-primary-base) !important;
//    opacity: 0.85;
//  }
//
//  &:hover {
//    svg {
//      opacity: 1;
//    }
//  }
//}

.orgchart-page {
  &__wrapper {
    background: var(--color-srf-primary-second);
  }
}

@media print {
  #appPreloader {
    display: none;
  }
  body {
    overflow: visible;
    padding: 0;
    margin: 0;
  }

  .page-content__wrapper {
    &, & > .ant-card-body {
      box-shadow: none !important;
      border: none !important;
      border-radius: 0!important;
    }
  }

  __orgchart-vfp-block {
    display: block;
  }

  #orgchart__wrapper {
    display: flex;
  }

  .app-content__wrapper .page-content__content, .app-content__wrapper .page-content__loader > .ant-spin-container,
  .app-content__wrapper .page-content__wrapper > .ant-card-body,
  .app-content__wrapper .page-content__wrapper {
    overflow: visible;
  }

  .orgchart-page__wrapper {
    //position: relative;
    //left: 0;
    //right: 0;
    //top: 0;
    //bottom: 0;
    //z-index: 987654321;
  }

  .app-layout__wrapper {
    position: relative;
  }
  //#orgchart__wrapper {
  //  position: fixed;
  //  left: 0;
  //  right: 0;
  //  top: 0;
  //  bottom: 0;
  //  z-index: 987654321;
  //}
  .orgchart-card__children__wrapper_no-margin-print {
    margin: 0!important;
    width: auto!important;
  }

  //#pageContent {
  //  overflow: hidden;
  //}

  .orgchart-card__wrapper {
    //overflow-y: hidden;
  }

  //* {
  //  scrollbar-color: transparent transparent;
  //  scrollbar-width: thin;
  //}

  .printHidden,
  .top-app-bar,
  .ant-layout-sider,
  .ant-dropdown,
  #page-header__wrapper,
  .zdrm-webphone-top {
    display: none !important;
  }
  .ant-layout-has-sider {
    margin-top: 0 !important;
  }
  .app-wrapper__main {
    overflow: visible;
  }
  .page-content__content {
    overflow: visible;
  }
  .app-content__wrapper {
    padding: 0 !important;
    box-shadow: none !important;
  }
  .__orgchart-vfp-block {
    position: static !important;

    .anticon.anticon-edit {
      display: none;
    }
  }
  * {
    -webkit-print-color-adjust: exact;
  }
  @page {
    //size: auto;
    //size: A3 landscape;
  }
}

.__vfp-editable {
  textarea {
    padding-left: 2px !important;
    padding-right: 2px !important;
    display: inherit;
  }

  &.ant-typography-edit-content {
    margin-left: 12px;
    margin-bottom: -5px !important;
  }
}

.orgchart-wrapper {
  transition: opacity .15s ease-in;

  &_loading {
    opacity: 0;
  }

}
