.regulation-collapse-panel {
  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 8px 0!important;
      display: grid;
      gap: 8px;
    }
  }
}